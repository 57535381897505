import { capitalizeFirstLetter } from '@/utils/helpers'

import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { FaVimeoV } from '@react-icons/all-files/fa/FaVimeoV';
import { FaPinterestP } from '@react-icons/all-files/fa/FaPinterestP';
import { FaRedditAlien } from '@react-icons/all-files/fa/FaRedditAlien';
import { FaMediumM } from '@react-icons/all-files/fa/FaMediumM';
import { FaTwitch } from '@react-icons/all-files/fa/FaTwitch';
import { FaDiscord } from '@react-icons/all-files/fa/FaDiscord';

function SocialIcon(socialType) {
  switch (socialType) {
    case 'facebook':
      return <FaFacebookF />
      break;
    case 'twitter':
      return <FaTwitter />
      break;
    case 'instagram':
      return <FaInstagram />
      break;
    case 'linkedin':
      return <FaLinkedinIn />
      break;
    case 'youtube':
      return <FaYoutube />
      break;
    case 'vimeo':
      return <FaVimeoV />
      break;
    case 'tiktok':
      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>
      break;
    case 'pinterest':
      return <FaPinterestP />
      break;
    case 'reddit':
      return <FaRedditAlien />
      break;
    case 'medium':
      return <FaMediumM />
      break;
    case 'twitch':
      return <FaTwitch />
      break;
    case 'discord':
      return <FaDiscord />
      break;
    default:
      return null
  }
}

export function SocialLinks({ socialLinks }) {

  return (
    <>
      {socialLinks.map(socialLink => (
        <a key={socialLink._key} href={socialLink.siteLink} target='_blank' rel='noopener noreferrer' className="px-1 py-3 text-2xl text-gray-300 transition-all hover:text-success" aria-label={`Link to ${capitalizeFirstLetter(socialLink.socialType)}`}>{SocialIcon(socialLink.socialType)}</a>
      ))}
    </>
  )
}
