export function Table(props) {

  if(!props || props?.rows?.length < 1) {
    return null
  }
  return (
    <div className="not-prose">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-slate-800 ring-opacity-5 mx-1">
              <table className="min-w-full divide-y divide-gray-300">

                {props?.rows[0]?.cells?.filter(Boolean)?.length > 0 && (
                  <thead className="bg-gray-50">
                    <tr>
                      {props?.rows[0]?.cells?.map((val, i) =>
                        <th key={i} scope="col" className={i === 0 ? 'py-3 pl-4 pr-3 text-left text-xs font-medium tracking-wide text-gray-500 sm:pl-6' : 'px-3 py-3 text-left text-xs font-medium tracking-wide text-gray-500'}>
                          {val}
                        </th>
                      )}
                    </tr>
                  </thead>
                )}

                <tbody className="divide-y divide-gray-200 bg-white">
                  {props?.rows?.length > 1 && props?.rows?.slice(1).map((row, i) =>
                    <tr key={i} className={i % 2 === 0 ? undefined : 'bg-gray-50'}>
                      {row.cells.map((cell, i) => <td key={i}
                        className={i === 0 ? 'whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-700 sm:pl-6' : 'whitespace-nowrap px-3 py-4 text-sm text-gray-700'}>{cell}</td>)}
                    </tr>
                  )}
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
