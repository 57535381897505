import InstagramEmbed from 'react-instagram-embed';

export function InstagramPost({ url }) {

  if (!url) {
    return null
  } else if(!process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || !process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_TOKEN){
    return null
  } else if (url.includes('https') || url.includes('http')) {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <InstagramEmbed
          url={url}
          clientAccessToken={`${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}|${process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_TOKEN}`}
          maxWidth={320}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        />
      </section>
    )
  } else {
    return null
  }
}
