import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import toast from "@/components/toast";
import { SiteConst } from "@/lib/constants"
import axios, { post } from 'axios';

import Recaptcha from 'react-recaptcha'

export function JobApp({ webform }) {
  // console.log({ webform })
  const router = useRouter();
  const [query, setQuery] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    positionDesired: "",
    message: "",
    recaptchaResponse: "",
    isVerified: false,
    file: {},
    filename: "",
    createObjectURL: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const notify = React.useCallback((type, message) => {
    toast({ type, message });
  }, []);

  // Google Recaptcha
  let recaptchaLoaded = function () {
    console.log('Recaptcha loaded')
  }
  let expiredCallback = function () {
    console.log('Recaptcha expired')
    setQuery((prevState) => ({
      ...prevState,
      'isVerified': false,
      'recaptchaResponse': null,
    }));
  }
  let verifyCallback = function (response) {
    if (response) {
      setQuery((prevState) => ({
        ...prevState,
        'isVerified': true,
        'recaptchaResponse': response,
      }));
    } else {
      setQuery((prevState) => ({
        ...prevState,
        'isVerified': false,
        'recaptchaResponse': null,
      }));
    }
  }
  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const fileSelectedHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const i = e.target.files[0];
      // console.log({i})
      if(i.type !== 'application/pdf'){
        notify("error", 'Please upload only PDF files');
        setQuery((prevState) => ({
          ...prevState,
          "file": "",
          "filename": "",
          "createObjectURL": "",
        }));
        return
      }
      if(i.size > 2600000){
        notify("error", 'Please upload a smaller PDF. Under 2.5MB');
        setQuery((prevState) => ({
          ...prevState,
          "file": "",
          "filename": "",
          "createObjectURL": "",
        }));
        return
      }
      const dateTime = Date.now();
      const fileName = `${dateTime}-${i.name}`
      setQuery((prevState) => ({
        ...prevState,
        "file": i,
        "filename": fileName,
        "createObjectURL": URL.createObjectURL(i),
      }));

    }
  };

  async function googleRecaptcha(formSubmission, axiosConfig) {
    // let response
    try {
      await axios.post('/api/google-recaptcha-v2', formSubmission, axiosConfig)
      if (response.status >= 400) {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(SiteConst.CONTACT_ERROR_MESSAGE);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let formData = new FormData();

    formData.append('emailfrom', webform.formSettings.emailfrom);
    formData.append('emailto', webform.formSettings.emailto);
    formData.append('subject', webform.formSettings.subject);
    formData.append('firstName', query.firstName);
    formData.append('lastName', query.lastName);
    formData.append('phone', query.phone);
    formData.append('email', query.email);
    formData.append('positionDesired', query.positionDesired);
    formData.append('message', query.message);
    formData.append('filename', query.filename);
    formData.append('file', query.file);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    try {
       await axios.post('/api/upload-and-email', formData, config)
        .then(res => {
          console.log(res.data);
          console.log(query.filename);
          console.log(formData);
        })
      notify("success", 'Thank you. Your resume has been sent.');
      setQuery({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        positionDesired: "",
        message: "",
        recaptchaResponse: "",
        isVerified: false,
        file: {},
        filename: "",
        createObjectURL: "",
      });
      setIsLoading(false);
      if (webform?.formSettings?.thankyou) {
        router.push(webform.formSettings.thankyou)
      }
    } catch (error) {
      // console.log({error})
      // throw new Error(SiteConst.CONTACT_ERROR_MESSAGE);
      notify("error", error?.response?.data?.message || 'There has been an error with your submission. Please refresh your page and try again.');
    }

    setIsLoading(false);
  }

  return (
    <div>
      <Script key={`recaptcha`} src='https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit' strategy="lazyOnload" />
      <form className="grid w-full grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2" onSubmit={handleSubmit}>

        <div className="sm:col-span-1">
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
            First Name <span className='font-extrabold text-red-700'>*</span>
          </label>
          <div className="mt-1">
            <input
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="given-name"
              required
              value={query?.firstName}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-1">
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
            Last Name <span className='font-extrabold text-red-700'>*</span>
          </label>
          <div className="mt-1">
            <input
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="family-name"
              required
              value={query?.lastName}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email address <span className='font-extrabold text-red-700'>*</span>
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={query?.email}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone Number <span className='font-extrabold text-red-700'>*</span>
          </label>
          <div className="mt-1">
            <input
              id="phone"
              name="phone"
              type="tel"
              autoComplete="phone"
              required
              value={query?.phone}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="positionDesired" className="block text-sm font-medium text-gray-700">
            Position Desired
          </label>
          <div className="mt-1">
            <input
              id="positionDesired"
              name="positionDesired"
              type="text"
              autoComplete="positionDesired"
              // required
              value={query?.positionDesired}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              autoComplete="message"
              rows={5}
              value={query?.message}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Upload Your Resume (pdf) <span className='font-extrabold text-red-700'>*</span>
          </label>
          <div className="mt-1">
            <input className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="file" onChange={(e) => fileSelectedHandler(e)} required accept=".pdf, application/pdf" />
          </div>
        </div>


        <div className="sm:col-span-2">
          <Recaptcha
            sitekey={process?.env?.NEXT_PUBLIC_GOOGLE_RECAPTCHA_PUBLIC}
            render='explicit'
            verifyCallback={verifyCallback}
            onloadCallback={recaptchaLoaded}
            expiredCallback={expiredCallback}
            size='compact'
          />
        </div>

        <div className="sm:col-span-2">
          <button
            type="submit"
            className={`flex justify-center w-full md:w-auto px-6 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600 ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={isLoading || !query.isVerified || (!query.firstName || !query.lastName || !query.phone || !query.email || !query.recaptchaResponse || !query.file || !query.createObjectURL)}>
            {isLoading === false ? <>Submit</> : <><svg className="w-5 h-5 mr-3 -ml-1 text-purple animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>{' '}Loading</>}
          </button>
        </div>

      </form>

    </div>
  )
}
