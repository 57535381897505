import { Linker } from '@/components/content/linker'

export function BottomFooterNav({bottomFooterNav}) {
  return (
    <ul className="flex sm:ml-4">
      {bottomFooterNav?.map(link => (
        <li key={link._key}><Linker url={link.siteLink} classes="mr-2 text-xs text-gray-200 md:text-sm hover:underline">{link.title}</Linker></li>
      ))}
    </ul>
  )
}
