import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import toast from "@/components/toast";
import { SiteConst } from "@/lib/constants"
import axios from 'axios';
import Recaptcha from 'react-recaptcha'

export function Contact({ webform }) {
  const router = useRouter();
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
    recaptchaResponse: "",
    isVerified: false
  });

  const [isLoading, setIsLoading] = useState(false);

  const notify = React.useCallback((type, message) => {
    toast({ type, message });
  }, []);


  // Google Recaptcha
  let recaptchaLoaded = function () {
    console.log('Recaptcha loaded')
  }
  let expiredCallback = function () {
    console.log('Recaptcha expired')
    setQuery((prevState) => ({
      ...prevState,
      'isVerified': false,
      'recaptchaResponse': null,
    }));
  }
  let verifyCallback = function (response) {
    if (response) {
      setQuery((prevState) => ({
        ...prevState,
        'isVerified': true,
        'recaptchaResponse': response,
      }));
    } else {
      setQuery((prevState) => ({
        ...prevState,
        'isVerified': false,
        'recaptchaResponse': null,
      }));
    }
  }

  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  async function googleRecaptcha(formSubmission, axiosConfig) {
    let response
    try {
      response = await axios.post('/api/google-recaptcha-v2', formSubmission, axiosConfig)
      if (response.status >= 400) {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(SiteConst.CONTACT_ERROR_MESSAGE);
    }
  };

  // FIXME: This breaks on production
  async function addMessageToSanity(formSubmission, axiosConfig) {
    let response
    try {
      response = await axios.post('/api/create-message', formSubmission, axiosConfig)
      if (response.status >= 400) {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(SiteConst.CONTACT_ERROR_MESSAGE);
    }
  };

  async function sendEmailUsingMailgun(formSubmission, axiosConfig) {
    let response
    try {
      response = await axios.post('/api/email', formSubmission, axiosConfig)
      if (response.status >= 400) {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(SiteConst.CONTACT_ERROR_MESSAGE);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (query.isVerified && query.name && query.email && query.message) {
      let formSubmission = {
        ...query,
        ...webform.formSettings
      };
      formSubmission = JSON.parse(JSON.stringify(formSubmission));
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json;',
          'Access-Control-Allow-Origin': '*',
        }
      };
      try {
        await googleRecaptcha(formSubmission, axiosConfig);
        await sendEmailUsingMailgun(formSubmission, axiosConfig);
        // FIXME: This breaks on production
        // await addMessageToSanity(formSubmission, axiosConfig);
        notify("success", SiteConst.CONTACT_SUCCESS_MESSAGE);
        setQuery({
          name: "",
          email: "",
          message: "",
        });
        setIsLoading(false);
        if(formSubmission?.thankyou){
          router.push(formSubmission.thankyou)
        }
      } catch (error) {
        console.error(error)
        notify("error", error?.message || SiteConst.CONTACT_ERROR_MESSAGE);
        setIsLoading(false);
      }
    } else {
      notify("error", error?.message || SiteConst.CONTACT_ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Script key={`recaptcha`} src='https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit' strategy="lazyOnload" />
      <form className="grid w-full grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2" onSubmit={handleSubmit}>

        <div className="sm:col-span-1">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1">
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              value={query?.name}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={query?.email}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              autoComplete="message"
              required
              rows={5}
              value={query?.message}
              onChange={handleChange()}
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
        <Recaptcha
        sitekey={process?.env?.NEXT_PUBLIC_GOOGLE_RECAPTCHA_PUBLIC}
        render='explicit'
        verifyCallback={verifyCallback}
        onloadCallback={recaptchaLoaded}
        expiredCallback={expiredCallback}
        size='compact'
      />
        </div>

        <div className="sm:col-span-2">
          <button
            type="submit"
            className={`flex justify-center w-full md:w-auto px-6 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600 ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={isLoading || !query.isVerified || (!query.name || !query.email || !query.message || !query.recaptchaResponse)}>
            {isLoading === false ? <>Submit</> : <><svg className="w-5 h-5 mr-3 -ml-1 text-purple animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>{' '}Loading</>}
          </button>
        </div>

      </form>

    </div>
  )
}
