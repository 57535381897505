import React, { useState, useRef, useEffect } from 'react'
import { Linker } from '@/components/content/linker'


export function DropDown({link, classes}) {
  const [linkState, setLinkState] = useState(false)
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLinkState(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const handleClick = () => {
    setLinkState(linkState => !linkState);
    // console.log(`linkState ${linkState === true ? 'true' : 'false'}`)
  }

  return (
    <li>
      <div className="relative" ref={wrapperRef}>
        <button onClick={() => handleClick()} type="button" aria-expanded={linkState}
          className={`inline-flex items-center font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${classes}`}><span>{link.title}</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 ml-2 text-gray-500 group-hover:text-gray-500"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>

        <div className={`${linkState === true ? 'transform opacity-100 scale-100 visible absolute right-0 z-10 mt-2 origin-top-right rounded-md shadow-lg whitespace-nowrap min-w-[200px]' : 'transform opacity-0 scale-95 invisible absolute right-0 z-10 mt-2 origin-top-right rounded-md shadow-lg whitespace-nowrap'} `}>
          <div className="px-2 py-2 text-right bg-white rounded-md shadow">
            {link.links.map(subLink =>
              <Linker key={subLink._key} classes="text-gray-600 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:shadow-outline transition-all" url={subLink.siteLink}>{subLink.title}</Linker>
            )}
          </div>
        </div>

      </div>
    </li>
  )
}
