import PropTypes from 'prop-types'
import Image from 'next/image';
import { urlFor } from '@/lib/sanity'
import { Linker } from '@/components/content/linker'
import { format, parseISO, isBefore } from 'date-fns'
import { Grid } from "@/components/content/grid";
import { CalIcon } from '@/components/content/event/cal-icon'
import { ExclamationIcon } from "@heroicons/react/solid";

const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth }) => {
  return (<Image
    src={image}
    alt={imageAltText || ''}
    blurDataURL={imageBlur || ''}
    placeholder={imageBlur ? 'blur' : 'empty'}
    layout="responsive"
    width={adjustedWidth}
    height={adjustedHeight}
    // className="object-cover object-center w-full"
    className='object-cover object-center w-full transition-all duration-500 lg:h-48 md:h-36 hover:scale-110'
  />
  )
}

function Figure(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 615;
  const adjustedHeight = 300;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

function FigureLarge(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 918;
  const adjustedHeight = 448;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

EventsView.propTypes = {
  data: PropTypes.array
}

export function EventsView(props: { data: any; }) {
  const { data } = props
  return (
    <div>
      {/* Latest events event */}

      {data?.map(event =>
        <article key={event._id} className="flex flex-col mb-12 overflow-hidden rounded-md shadow-md">
          {event?.mainImage ?
            <div className="relative flex-shrink-0">
              <Linker url={`/events/${event?.slug}`}>
                <FigureLarge {...event?.mainImage} />
              </Linker>

              {event?.startDate?.utc && <Linker url={`/events/${event?.slug}`}><CalIcon startDate={event?.startDate?.utc} /></Linker>}
            </div> :
            <div className="relative flex-shrink-0 overflow-hidden">
              <Linker url={`/events/${event?.slug}`}>
                <figure className='bg-gradient-to-r from-brand-1-800 to-brand-2-800 pt-[48.78%] w-full relative'>
                </figure>
              </Linker>

              {event?.startDate?.utc && <Linker url={`/events/${event?.slug}`}><CalIcon startDate={event?.startDate?.utc} /></Linker>}
            </div>
          }

          <div className="flex flex-col justify-between flex-1 px-6 pt-4 pb-6 bg-white">
            <div className="flex-1">

              <div className='space-y-2 md:space-y-0 md:flex md:justify-between md:flex-row'>
                {event?.category?.slug &&
                  <p className="text-sm font-medium tracking-widest text-gray-500 uppercase title-font">
                    <Linker url={`/events/category/${event.category.slug}`} classes="hover:underline">
                      {event.category.title}
                    </Linker>
                  </p>}

                {(!isBefore(new Date(), new Date(parseISO(event?.startDate?.utc)))) &&
                  <span className="md:ml-auto inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-red-600 text-white space-x-2 flex uppercase">
                    <ExclamationIcon className='w-4' />
                    <span>Past Event</span>
                  </span>}
              </div>

              <h3 className="mt-2 mb-1 text-4xl font-semibold text-gray-900">
                <a href={`/events/${event?.slug}`} className="hover:underline">  {event.title}
                </a>
              </h3>

              {/* <p className="mb-2 text-sm text-gray-600">
              {event?.startDate?.utc && <time dateTime={event?.startDate?.utc}>{format(parseISO(event?.startDate?.utc), 'MM/dd/yyyy p')}</time>}
              {event?.endDate?.utc && <> to <time dateTime={event?.endDate?.utc}>{format(parseISO(event?.endDate?.utc), 'MM/dd/yyyy p')}</time></>}
            </p> */}

              {event.summary && <p className="text-xl text-gray-500">{event.summary}</p>}
            </div>

          </div>
        </article>
      ).shift()}



      {/* event View minus the latest events */}
      <Grid gridSize={2}>
        {data?.slice(1).map(event =>
          <article key={event._id} className="flex flex-col overflow-hidden rounded-md shadow-md">

            {event?.mainImage ?
              <div className="relative flex-shrink-0">
                <Linker url={`/events/${event?.slug}`}>
                  <Figure {...event?.mainImage} />
                </Linker>

                {event?.startDate?.utc && <Linker url={`/events/${event?.slug}`}><CalIcon startDate={event?.startDate?.utc} /></Linker>}

              </div> :
              <div className="relative flex-shrink-0 overflow-hidden">
                <Linker url={`/events/${event?.slug}`}>
                  <figure className='bg-gradient-to-r from-brand-1-800 to-brand-2-800 pt-[48.78%] w-full relative'>
                  </figure>
                </Linker>

                {event?.startDate?.utc && <Linker url={`/events/${event?.slug}`}><CalIcon startDate={event?.startDate?.utc} /></Linker>}
              </div>
            }

            <div className="flex flex-col justify-between flex-1 px-6 pt-4 pb-6 bg-white">
              <div className="flex-1">

                {event?.category?.slug &&
                  <p className="text-sm font-medium tracking-widest text-gray-500 uppercase title-font">
                    <Linker url={`/events/category/${event.category.slug}`} classes="hover:underline">
                      {event.category.title}
                    </Linker>
                  </p>}

                <h3 className="mt-2 mb-1 text-2xl font-semibold text-gray-900">
                  <a href={`/events/${event?.slug}`} className="hover:underline">  {event.title}
                  </a>
                </h3>

                {/* <p className="mb-2 text-sm text-gray-500">
              {event?.startDate?.utc && <time dateTime={event?.startDate?.utc}>{format(parseISO(event?.startDate?.utc), 'MM/dd/yyyy p')}</time>}
              {event?.endDate?.utc && <> to <time dateTime={event?.endDate?.utc}>{format(parseISO(event?.endDate?.utc), 'm /dd/yyyy p')}</time></>}
            </p> */}

                {event.summary && <p className="text-base text-gray-500">{event.summary}</p>}

              </div>


            </div>
          </article>
        )}
      </Grid>
    </div>
  )
}
