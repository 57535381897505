import React from 'react'
import { PencilIcon, XIcon } from '@heroicons/react/outline'
export function PreviewAlert({ asPath }) {
  return (
    <div className="bg-gray-800">
      <div className="py-2 inner-wrapper">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center flex-1 w-0">
            <span className="flex p-2 rounded-lg">
              <PencilIcon className="w-4 h-4 text-white" aria-hidden="true" />
            </span>
            <p className="my-0 ml-3 text-sm font-medium text-white truncate">
              <span className="md:inline">You are in Preview Mode.</span>
            </p>
          </div>
          <div className="w-auto mt-0">
            <a href={`/api/exit-preview?slug=${asPath}`}
              className="inline-flex items-center px-2 py-1 text-sm font-medium leading-4 text-white bg-gray-800 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Exit Preview
              <XIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
