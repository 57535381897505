import PropTypes from 'prop-types'
import Image from 'next/image';
import { urlFor } from '@/lib/sanity'
import { Linker } from '@/components/content/linker'
import { PortableText } from '@/lib/sanity'
import serializers from '@/components/layout/content-components/serializers'

const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth }) => {
  return (<Image
    src={image}
    alt={imageAltText || ''}
    blurDataURL={imageBlur || ''}
    placeholder={imageBlur ? 'blur' : 'empty'}
    layout="responsive"
    width={adjustedWidth}
    height={adjustedHeight}
    className='overflow-hidden transition-all duration-500 rounded-full lg:h-24 md:h-24'
  />
  )
}


function Figure(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 195;
  const adjustedHeight = 195;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);
  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }
  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

function FigureLarge(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 918;
  const adjustedHeight = 448;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

TeamView.propTypes = {
  data: PropTypes.array
}

function GroupComponent({ people }) {
  const TeamLength = people?.length || 3;
  return (
    <div className='mx-auto mt-4 space-y-16 sm:grid sm:grid-cols-2 sm:gap-8 sm:space-y-0 lg:gap-8 md:grid-cols-3 lg:max-w-4xl md:mt-6'>
      {people?.map(person =>
        <article key={person._id} className="flex flex-col items-center mb-12">
          {person?.headshot ?
            <div className="relative flex-shrink-0 w-52">
              <Linker url={`/profile/${person.slug}`}>
                <Figure {...person.headshot} />
              </Linker>
            </div> :
            <div className="relative flex-shrink-0">
              <figure className='w-48 h-48 rounded-full bg-gradient-to-r from-brand-1-800 to-brand-2-800'></figure>
            </div>}
          <div className="flex flex-col items-center px-6 pt-4 pb-6 bg-white">
            <div className="flex-1 text-center">
              <h3 className="mb-1 text-2xl font-semibold text-gray-900 ">
                <a href={`/profile/${person.slug}`} className="hover:underline">
                  {person.title}
                </a>
              </h3>
              <h4 className="text-gray-500">{person.proTitle}</h4>
            </div>
            <p className="pt-3 pb-0">
              <Linker url={`/profile/${person.slug}`} classes="text-base font-semibold text-indigo-600 hover:text-indigo-500 flex items-center">
                View Profile<svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h14"></path><path d="M12 5l7 7-7 7"></path></svg>
              </Linker>
            </p>
          </div>
        </article>
      )}
    </div>
  )
}

export function TeamView(props: { view: any; }) {
  const { groups } = props?.view
  // lg:max-w-4xl
  return (
    <div>
      {groups.map(group =>
        <div key={group._id} className="mb-10 md:mb-14">
          <h2 className="mx-auto text-3xl font-bold md:text-4xl lg:text-6xl lg:max-w-4xl">{group.title}</h2>
          {(group?.body?.length && group?.body[0]?.children.length) && <div className='mx-auto mt-4 mb-12 text-base text-gray-500 md:text-lg lg:text-xl lg:max-w-4xl'><PortableText blocks={group?.body} serializers={serializers} /></div>}
          {(group?.people && group?.people.length >= 1) && <GroupComponent people={group.people} />}
        </div>)}
    </div>
  )
}
