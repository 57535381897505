import PropTypes from 'prop-types'

Tagline.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string || 'md',
  classes: PropTypes.string,
  theme: PropTypes.string || 'light',
}

export function Tagline(props: { text: string; size: string; classes: string; theme: string; }) {

  const { text, size, classes, theme } = props

  if(!size){
    return <p className={`mb-1 text-xs font-semibold tracking-widest uppercase title-font ${classes} ${theme === 'light' ? 'text-brand-2-800' : 'text-white'}`}>{text}</p>
  } else if(size === 'sm') {
    return <p className={`mb-1 text-xs font-semibold tracking-widest uppercase title-font ${classes} ${theme === 'light' ? 'text-brand-2-800' : 'text-white'}`}>{text}</p>
  } else if(size === 'md') {
    return <p className={`text-sm md:text-base font-semibold tracking-wider uppercase ${classes} ${theme === 'light' ? 'text-brand-2-800' : 'text-white'}`}>{text}</p>
  } else {
    return <p className={`mb-1 text-xs font-semibold tracking-widest uppercase title-font ${classes} ${theme === 'light' ? 'text-brand-2-800' : 'text-white'}`}>{text}</p>
  }

}