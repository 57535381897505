import getVideoId from 'get-video-id'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

export function Youtube(props) {
  if (!props || !props.url) {
    return <div>Missing YouTube URL</div>
  }
  const { id } = getVideoId(props.url)

  if (!id) {
    return <div>Missing YouTube URL</div>
  }
  return (<div className='not-prose'>
    <LiteYouTubeEmbed
      id={id}
      adNetwork={false}
      noCookie
      thumbnail={''}
    /></div>
  )
}
