import Image from 'next/image';
import { urlFor } from '@/lib/sanity'
import { Linker } from '@/components/content/linker'
import { CalIcon } from '@/components/content/event/cal-icon'

const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth }) => {
  return (<Image
    src={image}
    alt={imageAltText || ''}
    blurDataURL={imageBlur || ''}
    placeholder={imageBlur ? 'blur' : 'empty'}
    layout="responsive"
    width={adjustedWidth}
    height={adjustedHeight}
    className='object-cover object-center w-full transition-all duration-500 lg:h-48 md:h-36 hover:scale-110'
  />
  )
}

function Figure(props: { image?: any; alt?: any; }) {
  const image = props?.image.asset;
  const { alt } = props;
  const adjustedWidth = 615;
  const adjustedHeight = 300;
  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }
  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

export function Teaser({ event }) {
  return (
    <article key={event._id} className="flex flex-col overflow-hidden rounded-md shadow-md">

      {event?.mainImage ?
        <div className="relative flex-shrink-0">
          <Linker url={`/events/${event?.slug}`}>
            <Figure {...event?.mainImage} />
          </Linker>

          {event?.startDate?.utc && <Linker url={`/events/${event?.slug}`}><CalIcon startDate={event?.startDate?.utc} /></Linker>}

        </div> :
        <div className="relative flex-shrink-0 overflow-hidden">
          <Linker url={`/events/${event?.slug}`}>
            <figure className='bg-gradient-to-r from-brand-1-800 to-brand-2-800 pt-[48.78%] w-full relative'>
            </figure>
          </Linker>

          {event?.startDate?.utc && <Linker url={`/events/${event?.slug}`}><CalIcon startDate={event?.startDate?.utc} /></Linker>}
        </div>
      }

      <div className="flex flex-col justify-between flex-1 px-6 pt-4 pb-6 bg-white">
        <div className="flex-1">

          {event?.category?.slug &&
            <p className="text-sm font-medium tracking-widest text-gray-500 uppercase title-font">
              <Linker url={`/events/category/${event.category.slug}`} classes="hover:underline">
                {event.category.title}
              </Linker>
            </p>}

          <h3 className="mt-2 mb-1 text-2xl font-semibold text-gray-900">
            <a href={`/events/${event?.slug}`} className="hover:underline">  {event.title}
            </a>
          </h3>
          {event.summary && <p className="text-base text-gray-500">{event.summary}</p>}
        </div>
      </div>
    </article>
  )
}
