// lib/sanity.js
import {
  createImageUrlBuilder,
  createPortableTextComponent,
  createPreviewSubscriptionHook,
  createCurrentUserHook,
} from 'next-sanity'
import {config} from './sanity.config'

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const urlFor = (source) => createImageUrlBuilder(config).image(source)

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config)

// Set up Portable Text serialization
export const PortableText = createPortableTextComponent({
  ...config,
  // Serializers passed to @sanity/block-content-to-react
  // (https://github.com/sanity-io/block-content-to-react)
  serializers: {},
})

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(config)

// import {
//   groq,
//   createClient,
//   createImageUrlBuilder,
//   createPreviewSubscriptionHook,
// } from 'next-sanity'

// const config = {
//   dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
//   projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
//   apiVersion: '2021-10-23',
//   useCdn: process.env.NODE_ENV === 'production',
// }

// export const imageBuilder = source => createImageUrlBuilder(config).image(source)
// export const usePreviewSubscription = createPreviewSubscriptionHook(config)
// export const client = createClient(config)
// export const previewClient = createClient({
//   ...config,
//   useCdn: false,
//   token: process.env.SANITY_PREVIEW_SECRET,
//   // withCredentials: true
// })

// export const getClient = (usePreview) => (usePreview ? previewClient : client)
// export default client