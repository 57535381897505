import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';
import { FaBug } from '@react-icons/all-files/fa/FaBug';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaInfo } from '@react-icons/all-files/fa/FaInfo';


export const displayIcon = (type: any) => {
  switch (type) {
    case "success":
      return <FaCheck />;
    case "info":
      return <FaInfo />;
    case "error":
      return <FaExclamationCircle />;
    case "warning":
      return <FaExclamationTriangle />;
    default:
      return <FaBug />;
  }
};

const ToastMessage = ({ type, message }) =>
  toast[type](
    <div style={{ display: "flex", }}>
      <div
        style={{
          fontSize: 15,
          paddingTop: 8,
          flexShrink: 0,
          textAlign: "center",
          width: "30px",
          color: "white"
        }}
      >
        {displayIcon(type)}
      </div>
      <div style={{ flexGrow: 1, fontSize: 15, padding: "8px 12px", color: "#333" }}>
        {message}
      </div>
    </div>
  );

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

ToastMessage.dismiss = toast.dismiss;

export default ToastMessage;
