import Head from 'next/head'
import { urlFor } from '@/lib/sanity'

export function SEO({ site, page, path }) {
  // console.log({ site, page, path })
  const pagePath = path?.replace(/^\/|\/$/g, '') || ''
  const { seoSettings: pageSEO } = page || null
  const { seoSettings: siteSEO } = site
  const siteURL = site?.siteURL?.replace(/^\/|\/$/g, '') || ''
  const url = (pagePath && siteURL) && siteURL + '/' + pagePath
  const title = pageSEO?.title || siteSEO?.title
  // console.log({url})
  const description = pageSEO?.description || siteSEO?.description
  const openGraphImage = pageSEO?.openGraphImage || siteSEO?.openGraphImage
  const ogImage = openGraphImage?._type === 'image' && urlFor(openGraphImage).width(1200).url()

  return (

    <Head>
      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
      {title && <title>{title}</title>}
      {description && <meta name='description' content={description} />}

      {title && <meta property='og:title' content={title} />}
      {description && <meta property='og:description' content={description} />}

      <meta name='twitter:creator' content='' />
      {ogImage && <meta property='og:image' content={ogImage} />}
      {ogImage && <meta name='twitter:card' content='summary_large_image' />}
      {title && <meta name='twitter:title' content={title} />}
      {description && <meta name='twitter:description' content={description} />}
      {ogImage && <meta property='twitter:image' content={ogImage} />}

      {url && <meta property='og:url' content={url} />}
      {url && <meta property='canonical' content={url} />}
      <meta property='og:type' content='website' />
      <meta name="apple-mobile-web-app-status-bar" content="#1F2937" />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f25f3f" />
      <meta name="msapplication-TileColor" content="#1F2937" />
      <meta name="theme-color" content="#1F2937" />

      <link rel='preconnect' href='https://cdn.sanity.io' />

    </Head>
  )
}
