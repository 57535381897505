import React, { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Text } from '@/components/layout/content-components/serializers/text'
import { classNames } from '@/utils/helpers'

export function FaqItem({ title, text }) {
  const [open, setOpen] = useState(false)
  return (
    <div className="pt-6">
      <dt className="text-lg font-medium leading-6 text-gray-900">
        <button type="button" className="flex items-start justify-between w-full text-left text-gray-400" onClick={() => setOpen(!open)}>

          <span className="font-medium text-gray-900">{title}</span>
          <span className="flex items-center ml-6 h-7">
            <ChevronDownIcon
              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
              aria-hidden="true"
            />
          </span>
        </button>
      </dt>
      {open ? <dd className="mt-2 text-base text-gray-500"><Text blocks={text} /></dd> : <dd></dd>}
    </div>
  )
}
