import PropTypes from 'prop-types'
import { Text } from '@/components/layout/content-components/serializers/text'
import { FaqItem } from './faqItem'

FAQItems.propTypes = {
  layout: PropTypes.string,
  faqs: PropTypes.array,
}

function TopMargin(type: string) {
  if (type === 'buttonIcon' || type === 'button') {
    return 'mt-4'
  } else {
    return 'mt-3'
  }
}

export function FAQItems(props: { faqs: any; layout: string; }) {
  const { faqs, layout } = props
  if (layout === 'accordion') {
    // return null
    return (
      <dl className="space-y-6 divide-y divide-gray-200">
        {faqs?.map((faq) => (
          <FaqItem key={faq._key} title={faq?.title} text={faq?.text} />
        ))}
      </dl>
    )
  } else if (layout === 'simple-stacked') {
    return (
      <dl className="space-y-12">
        {faqs?.map((faq) => (
          <div key={faq.question}>
            <dt className="text-lg font-medium leading-6 text-gray-900">{faq?.title}</dt>
            <dd className="mt-2 text-base text-gray-500"><Text blocks={faq?.text} /></dd>
          </div>
        ))}
      </dl>
    )
  }
}
