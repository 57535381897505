import Image from 'next/image';
import { getImageDimensions } from '@sanity/asset-utils'
import { urlFor } from '@/lib/sanity'


const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth  }) => {
  return (<Image
        src={image}
        alt={imageAltText || ''}
        blurDataURL={imageBlur || ''}
        placeholder={imageBlur ? 'blur' : 'empty'}
        layout="intrinsic"
        width={adjustedWidth}
        height={adjustedHeight}
        className="object-cover object-center w-full"
      />
  )
}

export function Figure(props) {
  // console.log({props})
  const image = props?.asset;
  const { alt } = props;
  // const { asset } = props;
  const { aspectRatio } = getImageDimensions(props?.image?.asset)
  // console.log({aspectRatio})
  // return null

  const adjustedWidth = props?.width || 788;
  const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure className='text-center'><ImageComponent image={imageScaled(props?.image)} imageBlur={imageBlur(props?.image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}
