import { Header } from '@/components/layout/header'
import { ContentComponents } from '@/components/layout/content-components'
import { Footer } from '@/components/layout/footer'
import { PreviewAlert } from '@/components/shared/preview-alert'

export function Layout({preview, siteNav, topNav, topFooterNav, bottomFooterNav, siteSettings, asPath, contentComponents, webform, view}) {
  return (
    <div className="flex flex-col min-h-screen">
      {preview && <PreviewAlert asPath={asPath} />}
      <Header siteNav={siteNav} topNav={topNav} siteSettings={siteSettings} />
      <ContentComponents contentComponents={contentComponents} webform={webform} view={view} layout={true} />
      <Footer siteSettings={siteSettings} topFooterNav={topFooterNav} bottomFooterNav={bottomFooterNav}  />
    </div>
  )
}
