import { format, parseISO } from 'date-fns'

export function CalIcon({startDate}) {
  return (
    <div className="absolute font-medium shadow-xl min-w-20 xl:min-w-24 min-h-48 top-4 right-4">
    <div className="flex-none w-20 text-center rounded-t xl:w-24 lg:rounded-t-none lg:rounded-l ">
      <div className="block overflow-hidden text-center rounded-t ">
        <div className="py-1 text-sm text-white xl:text-base bg-brand-1-700">
          {format(parseISO(startDate), 'MMM Y')}
        </div>
        <div className="pt-1 bg-white border-l border-r border-white">
          <span className="text-3xl font-bold leading-tight xl:text-4xl">
          {format(parseISO(startDate), 'do')}
          </span>
        </div>
        <div className="-mb-1 text-center bg-white">
          <span className="text-sm">
            {format(parseISO(startDate), 'EEEE')}
          </span>
        </div>
        <div className="pb-2 text-center bg-white border-white rounded-b-lg">
          <span className="text-xs leading-normal">
          {format(parseISO(startDate), 'p')}
          </span>
        </div>
      </div>
    </div>
  </div>
  )
}
