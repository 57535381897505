import PropTypes from 'prop-types'
import { Linker } from '@/components/content/linker'
import Icon from '@/components/shared/react-icons-wrapper'
import { transformToUpperCase } from '@/utils/helpers'

TextIconCTA.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  style: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string,
  theme: PropTypes.string,
}

export function TextIconCTA(props: { title: string; url: string; icon: string; type: string; style: string; size: string; theme: string; }) {

  const { title, url, icon, type, style, size, theme } = props

  function StyleClasses(style: string, theme: string) {
    if (style === 'primary' && theme === 'light') {
      return 'text-gray-900'
    } else if (style === 'primary' && theme === 'dark') {
      return 'text-white'
    } else if (style === 'secondary' && theme === 'light') {
      return 'text-gray-900'
    } else if (style === 'secondary' && theme === 'dark') {
      return 'text-white'
    } else {
      return null
    }
  }

  function SizeClasses(size: string) {
    if (size === 'lg') {
      return 'font-medium text-base lg:text-lg'
    } else if (size === 'sm') {
      return 'font-medium text-sm lg:text-base'
    } else {
      return null
    }
  }

  return (
    <p>
        <Linker url={url} classes={`${SizeClasses(size)}
        ${StyleClasses(style, theme)}`}>
          {title}
          {icon && <Icon name={transformToUpperCase(icon, ['-'])} size={'1.4em'} color={'indigo-600'} aria-hidden="true" className="inline-block ml-1 text-inherit" />}
        </Linker>
      </p>
  )
}
