import '../styles/global.css'
import { useEffect } from 'react';
import type { AppProps } from "next/app";
import { UserProvider } from '@supabase/supabase-auth-helpers/react';
import { supabaseClient } from '@supabase/supabase-auth-helpers/nextjs';
import { MyUserContextProvider } from '@/utils/useUser';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  return (
    <UserProvider supabaseClient={supabaseClient}>
    <MyUserContextProvider supabaseClient={supabaseClient}>
      <Component {...pageProps} />
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        closeOnClick
        pauseOnHover
      />
    </MyUserContextProvider>
      </UserProvider>
  );
}
export default MyApp;
