
export function PDF(props) {
  const ID = props._key
  const height = props.height || 960
  const alt = props.alt ? (<>{props.alt}</>) : (<>Your browser can not view this PDF Embed, however you can click the link below to download.</>)
  const downloadText = props.downloadText && props.downloadText
  let PDFpath = props.file.asset._ref
  PDFpath = PDFpath.replace('file-', '')
  PDFpath = PDFpath.replace('-pdf', '')
  PDFpath = `https://cdn.sanity.io/files/${process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}/${process.env.NEXT_PUBLIC_SANITY_DATASET}/${PDFpath}.pdf`
  return (
    <div>
    {/*internalinstanceid={ID}*/}
      <object data={PDFpath} type='application/pdf' width='100%' height={height} >{alt}
      </object>
      {downloadText && (
        <p className='mt-4'><a href={PDFpath} target='_blank' rel='noopener noreferrer' className='inline-flex items-baseline space-x-3 group'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 not-sr-only"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg> <span className="group-hover:underline">{downloadText}</span></a></p>
        )}
    </div>
  )
}
